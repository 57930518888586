// Import all of Bootstrap's CSS
@import "../../node_modules/bootstrap/scss/bootstrap";

//
// Custom styles
//
* {
  --bs-gutter-x: 0rem;
}
body {
  //   padding: 1.5rem;
  font-family: "Montserrat", sans-serif;
  background: url(../images/imagen_cabecera.png) top right no-repeat, #f9faf9;
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: "elegance";
  src: url(../fonts/Rounded_Elegance.woff);
  font-display: swap;
}

.logoTop {
  width: 60%;
  margin-bottom: 1rem;
}
.topContainer {
  padding: 1.5rem;
  margin-bottom: 0;

  font-family: "elegance", "Montserrat", sans-serif;
  color: #666633;
  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .textIntroTop {
    // font-size: 2rem;
    line-height: 2.3rem;
    padding-bottom: 2.5rem;
  }
}
.secondContainer {
  padding: 2rem;
  color: #fff;

  background: url(../images/simbolo_IG.png) no-repeat top left, #666633;
  h2 {
    font-family: "elegance", sans-serif;
    font-size: 2rem;
  }
}
.queFemText {
  padding: 2.5rem;
  color: #666633;
  h2 {
    font-size: 2.5rem;
    font-family: "elegance", sans-serif;
    margin-bottom: 1rem;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
.accordion {
  padding: 2.5rem;

  .accordion-item {
    border-bottom: 2px solid #669999;
    margin-bottom: 2rem;
    h2 > button {
      font-size: 1.85rem;
      font-family: "elegance", sans-serif;
      color: #669999 !important;
      text-align: left;
      background-color: #f9faf9;
      border: 0px;
    }
    // button::after {
    //   content: url(../images/plusSign.png);
    // }
  }
  .accordion-body {
    background-color: #f9faf9;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: none;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: none;
  --bs-accordion-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='90' height='90' viewBox='0 0 90 90'%3E%3Cimage id='_' data-name='+' width='90' height='90' xlink:href='data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAD3klEQVRogeWaPWsUURSGn4zRQvMDxKhFChVFRLSOVhJsRUFBMwwZUYIpglbWVoKIIkpGhlEhASWthFQxtSFIUNQihRL1B6iFH4mc+O4y2bu7brKT7M74wrKwc7L3ffbc3Jlzz+0gQ4Vx0gkcBY4B+4F9wA5gCVjUSJv0/gl4C7wBXgAvo8D/lZWbpsEEcwK4APQCs8CU3t8DH6PAX6r4Gxt3F7AHOAwc1/s08BiYbBZyzWBhnHQBF4Eh+7WBJ8DzKPB/OsGNfd9m4CRwXlm/A4xEgf/VCW5AqwYL48QT0HVgHLgZBf6CE9iEwjjpBq4Bp4AbAixN5ezBwjg5CMTAPDCcNVCV8QzwFtADBFHgzzlBNdQwWBgnA8rS5SjwJ5yAdVQYJ33AfcteFPgPMwEL48RWsXv6Rz8XBf4XJ2hj4LYDo1qQBqPA/+0EpeQ5n6z8si3AU2Ar0NcqKJPG7pOXp/JWUzXBlKkx4DPQHwX+DydogyUP/fI0Jo9VVRNM0+8bcKXyPtRiOPNyRd7uOQFSVTAtFPY/NZAVVBgnp+3lXFiD5GnZo7w6csC0pF/XQpHl9OvWKxPJ2znzKs8rtAJMN99YS3rLFopGJY+XzbO8l1WZMXuimN/o+1Qzktd5eXfB9OxnU3A4L1ApDWtKdpU+SmfMiMfX+zFpPSTP4+msLYOp9LCn9Jt5g0rJvA+JpZwxq6dm8pitkuR9RixlsAsq8PKux2LBU+qs8n1eADBj6DUmT9Xq7For33aSGGxL4qinbE3lHSolY+k1sAOiLIpeGZOnLbL3BQJ7Z0ye9v0+Opfzqw/GZGBL7VRvNSuxLHWmdmjrSrVUM2WH7Q7b9zgXVqGFKPCfNRC+6NRjRVFnldKlqhr8pWqqlKko8G/XislQnkF1aC+9EBJLh6eux64CzcLdxuSplbPHuZxf7TUmA3utFk5RdMiYPPWkjhcIzFimPfW2Dqs/lWuJwWbfS0+dw2k13fIuY5g2ptI9rFx55lzlnYAS2CRwRI22XCqMk53GIJa/YJqOd9QezauuGkOpKZ9+nBqxnm8esybPp8SwrDKYuvM31PPNm26pjfvVAZOMuEc931xIXnvS2XLAdOQgsEa2er5tLXm8rxMFizXBBDenKTn6rz7vKrWgVyaSt1FNQeeYRM1yJYyTB2pk97fb1oFKk0fA9yjwLzkB1TKW0iCwDbjbTvWavNyVt0EnQKprWOkeUyN7oNUnB+TnoaDO1vNTL2OlPu8ZSzkw0coFRWNPyMuZelD810eOKgYo3iGx1GDFO9ZXMXixDmJWMVSso7PV1OBh5+W9zHU97Az8AZi2moloJS60AAAAAElFTkSuQmCC'/%3E%3C/svg%3E%0A");
  --bs-accordion-btn-icon-width: 2.5rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='54' height='54' viewBox='0 0 54 54'%3E%3Cimage id='_-' data-name='-' width='54' height='54' xlink:href='data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAADu0lEQVRogeWaPWsUURSGn4zRQv0B4leRQkUREW1sopUECxtRUNAMQ0aUkBRBK2srIYUiiiPDGCEBJY1FCKliGhtDEFHUIoXixw+IFn5FzvruOu6drGuyye5M3mYge7L3ffbcuTPnnttGAxXGSTtwEDgM7AZ2AZuBeeCnRlqj6wfgFfASeAw8jQL/e6PcLBlMMEeBc0AnMANM6voGeBcF/nzV/9i424AdwH7giK5TwBAwsVTIRYOFcbIROA/0268N3AfGosD/5gTX931rgWPAWWX9OnAnCvw5J7gO/TdYGCeegK4Ao8C1KPDfO4FLUBgnW4DLwAngqgDLU7nxYGGc7AViYBYYaDRQxngGOAh0AEEU+M+doAVUN1gYJz3K0sUo8MedgGVUGCddwC3LXhT4dxsCFsaJrWI3daOfiQL/kxO0MnCbgGEtSL1R4P9wglLynL/8/WXrgAfAeqCrWVAmjd0lLw/kbUEtCKZMjQAfge4o8L86QSsseeiWpxF5zNSCYJp+n4G+6udQk+HMS5+83XQCpEwwLRR2T/W0ElRZ8lTyKK+OnMVDS/oj4FAz76l6pAXlCXC8+lHwV8b08I21pLc0FH8WlIvmWd4rqp6K9kYxu9LPqaVIXmfl3QXTu589gAfyApWSeb4ihpLSGTPi0eV+TVoOyfNoOmslMJUe9pZ+LW9QKZn3frFUMmb11HQes1WWvE+LpQJ2TgVe3jUkFjylzirfsQKAGUOnMXmqVmcWW/m2ksRgWxIHPWVrMu9QKRlLp4HtEWVR9MyYPG2RvSkQ2Gtj8rTv9875OL96a0wGNt+KpcliJZb59tQObU2FcXIS2FIrZgX0Pgr8h3UM8zOz0CyC2jNKl0zV+Uu1ijyDatNeeiEkljZPXY9tBZqF243JUytnh/NxfrXTmAzshVo4RdE+Y/LUkzpSIDBjmfLU29qv/lSuJQabfU89dQ6n1HTLu4xhypjKz7BK5ZlzVXYCymATwAE12nKpME62GoNYfoNpOl5XezSvumQM5aZ8+nXqjvV885g1eT4hhpIqYOrOX1XPN28aVBt3zgGTjLhDPd9cSF470tli1bSR+D0ln2tKDv+rz9tMyduwpqBzTMLJWOrXuK1GdnerbR2oNLkHfIkC/4ITkJWxlHqBDcCNVqrX5OWGvPU6AVJNw0r3iBrZPc0+OSA/dwV1upafWhkrHz84ZSkHxnWzNkUae1xeTtWCYlUfOaoaoHiHxFKDFe9YX9XgxTqImWGoWEdns1TnYefSXuayHnYGfgGezI8PcBqODwAAAABJRU5ErkJggg=='/%3E%3C/svg%3E%0A");
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

#carouselExampleIndicators > div.carousel-indicators {
  bottom: -40px;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  // border: 1px solid red; /* For testing: remove this */
}
.carousel-inner,
.carousel-cell {
  background-color: #f9faf9;
}

h3.blue {
  color: #669999;
  font-size: 2.2rem;
  font-weight: 300;
}

#carouselExampleCaptions .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #669999;
  background: transparent;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: 1;
  background-color: #669999;
  background-clip: padding-box;
  /* border-left: 0; */
  /* border-right: 0; */
  flex: 0 auto;
  width: 30px;
  height: 26.78px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  transition: opacity 0.6s;
  border-radius: 100%;
  border: 4px solid #f9faf9;
}
.carousel-indicators .active {
  background-color: #f9faf9;
  border: 4px solid #669999;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 1;
  color: #669999;
  text-align: center;
  opacity: 1;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 0;
  transition: opacity 0.15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: -102%;
}

.carousel-control-prev {
  left: -10%;
}
.carousel-control-next {
  right: -5%;
}

.contacteContainer {
  padding: 2rem;
  color: #fff;

  background-color: #669999;
  // background: url(../images/simbolo_IG.png) no-repeat top left, #666633;
  h2 {
    font-family: "elegance", sans-serif;
    font-size: 2rem;
  }
  .h3 {
    font-weight: 300;
  }

  input:not([type="checkbox"]) {
    background-color: #669999 !important;
    color: #f9faf9;
    border-width: 2px;
    border-style: none;
    border-color: #f9faf9;
    border-image: none;
    border: none;
    border-bottom: 2px solid f9faf9;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
    font-weight: bold;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #f9faf9;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #f9faf9;
  }
  .btn-primary {
    --bs-btn-color: #669999;
    --bs-btn-bg: #f9faf9;
    --bs-btn-border-color: #669999;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #fff;
    border-radius: 30px;
  }
  .btn-lg {
    --bs-btn-padding-y: 1rem;
    --bs-btn-padding-x: 3rem;
    --bs-btn-font-size: 0.9rem;
    --bs-btn-border-radius: var(--bs-border-radius-lg);
    font-weight: bold;
  }
  .endText {
    font-size: 1.5rem;
  }
}

.footerContainer {
  padding: 2rem;
  color: #fff;
  text-align: center;

  background-color: #666633;
  h2 {
    font-family: "elegance", sans-serif;
    font-size: 2rem;
  }
}

.hidden {
  display: none;
}

@media screen and (min-width: 1200px) {
  body {
    // max-width: 390px;
    margin: 0 auto;
    background: url(../images/imagen_cabecerax1600.jpg) top right no-repeat,
      #f9faf9;
    background-size: 90vw;
    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
      font-weight: 300;
    }
    h2 {
      font-size: 3rem !important;
    }
  }
  ul {
    font-size: 1.5rem;
  }
  .topContainer {
    height: 53vh;
    margin: 10vh;
    margin-left: 18vw;
    padding-left: 0.2rem;
    min-height: 420px;
    min-width: 400px;
    width: 20vw;
    .logoTop {
      width: 420px;
    }
    p {
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 300;
    }
    .textIntroTop {
      // font-size: 3rem;
      line-height: 3.9rem;
    }
  }
  .secondContainer {
    padding: 2rem;
    color: #fff;

    background: url(../images/simbolo_IG.png) no-repeat top 100vw, #666633;
    h2 {
      font-family: "elegance", sans-serif;
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 1600px) {
  .topContainer {
    min-height: 53vh;
  }
}

.textIntroTop {
  font-size: clamp(2rem, -0.875rem + 8.333vw, 2.5rem);
}

a,
a:visited,
a:hover {
  color: #fff;
  text-decoration: underline;
}

a.whatsButton {
  text-decoration: none;
}
.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}

.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}
